
import {displayFieldValue} from '../../helpers/PerfectView';
import {Component, Prop, Watch} from 'vue-property-decorator';
import Vue from 'vue';
import {uploadDossierFile} from '../../services/app-service';
import {IRequiredDocument} from '../../models/file-entry';
import PazToast from '@pazion/pazion-core/src/components/utils/PazToast.vue';

const displayFields: string[] = [
  'SDO_plaatshuwelijk',
  'SDO_dossiernaam',
  'SDO_datumhuwelijk',
];

@Component({
  components: {
    PazToast
  },
  methods: {
    displayFieldValue
  }
})
export default class DossierRequiredDocument extends Vue {

  $refs!: {
    toast: PazToast
  };
  fileRules = [
    value => {
      if (!value || !value.length) {
        return true;
      }
      console.log(value);
      for (let v of value) {
        if (v.size > 16000000)
          return 'Bestanden moeten kleiner zijn dan 16 mb';
      }
      return true;
    },
  ];
  @Prop()
  public requiredDocument!: IRequiredDocument;

  @Prop()
  public dossierId!: number;

  public loading: boolean = false;
  public uploadSuccess: boolean = false;
  public notApplicable: boolean = false;

  public description: string = '';
  public uploadedFiles = [];

  public files: object = {};
  // @VModel()
  // public dossier!: object;

  get presentFiles(){
    return [].concat(this.requiredDocument.uploadedFiles, this.uploadedFiles) ;
  }
  async uploadFile() {
    this.loading = true;
    for (let file of this.files) {
      if (file.size > 16000000) {
        this.$refs.toast.show({message: 'Bestand [' + file.name + '] te groot!'});
        this.loading = false;
        return;
      }
    }

    const rsp = await uploadDossierFile(this.dossierId, this.files, this.requiredDocument.id, this.description);

    if (rsp.rsp.success) {
      this.$refs.toast.show({message: 'Bestand(en) succesvol geüpload!'});
      this.$emit('uploadSuccess');
      for (let file of this.files) {
        this.uploadedFiles.push(file.name);
      }
      if (this.requiredDocument.id > 0) {
        if(!this.requiredDocument.fileExists){
          this.$emit('bumpFileCount');
        }
        this.uploadSuccess = rsp.rsp.success;
        this.requiredDocument.fileExists = true;
      }
      this.files = {};
    }
    this.loading = false;
  }

  mounted() {
    this.notApplicable = this.requiredDocument.isPresent && !this.requiredDocument.fileExists;
    // console.log(this.notApplicable);
  }

  @Watch('notApplicable')
  public notApplicableChanged(newValue: boolean, oldValue: boolean) {
    // console.log(this.files);
    // console.log(newValue);
    uploadDossierFile(this.dossierId, [], this.requiredDocument.id, this.description).then((rsp) => {

      // console.log(rsp);
    });
  }

  get canUpload() {
    if (this.files instanceof File) {
      return this.files.size < 16000000;
    }
    if (this.files.length) {
      for (let file of this.files) {
        if (file.size > 16000000) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  get showSuccesAlert() {
    return this.requiredDocument.fileExists || this.isuploadSuccess || this.notApplicable;
  }

  get isuploadSuccess() {
    return this.uploadSuccess;
  }
}
