export interface IDate {
    date: string;
    timezone: string;
}

export function dateObjToDisplayDate(date: IDate) {

}

export function dateObjToDisplayTime(date: IDate) {

}

export function dateObjToDisplay(date: IDate) {

    const parsedDate = Date.parse(date.date);
    if (isNaN(parsedDate)) {
        return date.date;
    }
    const dt = new Date(parsedDate);
    return dt.toLocaleDateString() + ' @ ' + dt.toLocaleTimeString('nl-NL');
}
