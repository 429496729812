
import {displayFieldValue} from '../../helpers/PerfectView';
import {Component, Prop, VModel, Watch} from "vue-property-decorator";
import Vue from "vue";

const displayFields: string[] = [
  "SDO_plaatshuwelijk",
  // "SDO_dossiernaam",
  "SDO_datumhuwelijk",
	//"IsClosed",
];

@Component({
  components: {},
  methods: {
    displayFieldValue
  }
})
export default class DossierProperties extends Vue {

  @VModel()
  public dossier!: object;

  get filteredProps() {
    return this.dossier.filter(e => {
      return displayFields.includes(e.name);
    }).sort((e: object, e2: object) => {
      return e.sortIndex - e2.sortIndex;
    });
    return this.fields;
  }
}
