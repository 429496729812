import {deleteRequest, getRequest} from '@pazion/pazion-core/src/services/auth-service';
import {postRequest} from "@pazion/pazion-core/src/services/auth-service";
import {putRequest} from '@pazion/pazion-core/src/services/auth-service';


export async function getDossiers() {

    return (await getRequest('app/dossiers')).data;
}

export async function getDossiersById(id: number) {
    return (await getRequest('app/dossiers/' + id)).data;
}
export async function updateDossierProperty(dossierId: number, fieldName: string, data: any) {
    return (await putRequest('app/dossier-property/' + dossierId,{
	  dossierId, fieldName, value:data
	})).data;
}

export async function getDossierFilesByDossier(id: number) {
    return (await getRequest('app/dossier-files/' + id)).data;
}

export async function downloadDossierFile(id: number) {
    return (await getRequest('app/download-dossier-file/' + id)).data;
}

export async function deleteDossierFile(id: number) {
  return (await deleteRequest('app/dossier-files/' + id)).data;
}
export async function uploadDossierFile(id: number, files: any, requiredDocumentId: number, description: string) {
    let formData = new FormData();
    for(let file of files) {
        formData.append('file[]', file);
    }
    formData.append('description', description);
    formData.append('requiredDocument', '' + requiredDocumentId);

    return (await postRequest('app/dossier-files/' + id, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })).data;
}
