
import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';
import {displayFieldValue} from '../../helpers/PerfectView';
import {authentication} from '@pazion/pazion-core/src/store';
import PersonProperty from './PersonProperty.vue';

const displayFields: string[] = [
  'DisplayName',
//  'PER_name',
  'COM_mobile',
  'PER_firstnames',
//  'PER_bsnnumber',
  'ADR_PRI_city',
//  'PER_anniversary',
  'ADR_PRI_street',
  'ADR_PRI_number',
//  'PER_initials',
//  'PER_firstname',
//  'PER_birthplace',
  'COM_email',
  'ADR_PRI_postalcode',
];
const editableFields: string[] = [
  'COM_mobile'
  // 'COM_email'
];

export interface IDisplayField {

}

@Component({
  components: {
	PersonProperty
  },
  methods: {
	displayFieldValue
  }
})
export default class DossierPerson extends Vue {


  @Prop({default: ''})
  public dossierId!: string;
  @Prop({default: 0})
  public personId!: number;
  @Prop()
  public fields!: Array<object>;

  public saving: boolean = false;


  canEdit(field: object) {
	if (authentication.getProfile?.id !== this.personId) {
	  return false;
	}

	return editableFields.includes(field.name);
  }

  created() {
  }

  mounted() {
  }

  get filteredProps() {
	return this.fields.filter(e => {
	  return displayFields.includes(e.name);
	}).sort((e: object, e2: object) => {
	  return e.sortIndex - e2.sortIndex;
	});
	return this.fields;
  }
}
