
import {Component, Vue} from 'vue-property-decorator';
import {requestNewPassword, requestPasswordReset} from '@pazion/pazion-core/src/services/auth-service';

@Component({})
export default class AppForgotPassword extends Vue {
  email: string = '';
  token: string = '';
  newPassword: string = '';
  newPassword2: string = '';
  messageType: string = 'info';
  loading: boolean = false;
  passwordUpdated: boolean = false;
  forceTokenField: boolean = false;
  required: any = [];
  message: string = '';

  public validate() {
    return false;
  }

  public async handleReset() {
    this.loading = true;
    this.message = '';
    this.messageType = 'info';
    const rsp = await requestPasswordReset(this.email, this.token);
    this.message = rsp.message;
    this.loading = false;
    if (rsp.success) {
      this.messageType = 'success';
    }
  }

  public async requestNewPassword() {
    this.loading = true;
    this.message = '';
    this.messageType = 'info';
    const rsp = await requestNewPassword(this.token, this.newPassword);
    this.message = rsp.message;
    this.loading = false;
    if (rsp.success) {
      this.passwordUpdated = true;
      this.messageType = 'success';
    }
    setTimeout(() => {
      this.$router.push({name: 'login'});
    }, 3000);
  }

  mounted() {
    this.token = this.$route.params.token;
  }

  get showTokenField() {
    return this.forceTokenField || this.token && this.token.length > 0;
  }

  get canRequestNewPassword() {
    console.log(this.newPassword == this.newPassword2);
    return this.newPassword.length >= 4 && this.newPassword == this.newPassword2;
  }

  get canSubmit() {
    return this.email.length > 0 && /\S+@\S+\.\S+/.test(this.email);
  }

}
