import '@pazion/pazion-core/src/config';
import {addRoute} from '@pazion/pazion-core/src/router';
import Register from './views/Register.vue';
import DossierOverzicht from './views/dossier/DossierOverzicht.vue';
import DossierView from './views/dossier/DossierView.vue';
import {removeRouteByName} from '@pazion/pazion-core/src/router';
import Logout from '@pazion/pazion-core/src/views/user/Logout.vue';
import Dashboard from '@pazion/pazion-core/src/views/Dashboard.vue';
import AppForgotPassword from './views/user/AppPasswordForget.vue';
import AppLogin from './views/user/AppLogin.vue';
import DossierViewWithRequiredCategories from '@pazion/pazion-core/src/views/dossier/DossierViewWithRequiredCategories.vue';

addRoute({
  path: '/',
  name: 'Scheidendoejesamen',
  component: Dashboard,
  meta: {
	'show-in-nav': false,
	roles: ['user'],
	sort: -100
  },
});
addRoute({
  path: '/forgot-password/:token?',
  name: 'forgot-password',
  component: AppForgotPassword,
  meta: {
	guest: true,
  }
});
addRoute( {
  path: '/login',
  name: 'login',
  component: AppLogin,
  meta: {
	guest: true,
  }
});


removeRouteByName('test');
removeRouteByName('dossier');
removeRouteByName('dossier-course-page');
addRoute({
  path: '/register',
  name: 'register',
  component: Register,
});
addRoute({
  path: '/',
  name: 'Home',
  component: DossierOverzicht ,
});

addRoute({
  path: '/dossier/:id',
  name: 'dossier-view',
  component: DossierView,
});
addRoute({
  path: '/dossier2/:id',
  name: 'dossier-view2',
  component: DossierViewWithRequiredCategories,
});

