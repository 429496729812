
import {Component} from 'vue-property-decorator';
import FileTab from "@pazion/pazion-core/src/views/dossier/parts/FileTab.vue";
import Vue from 'vue';
import {getDossierFilesByDossier, getDossiers, getDossiersById} from '../../services/app-service';
import DossierPerson from '../../components/dossier/DossierPerson.vue';
import DossierProperties from '../../components/dossier/DossierProperties.vue';
import FileDropper from '@pazion/pazion-core/src/components/utils/FileDropper.vue';
import DossierRequiredDocument from '../../components/dossier/DossierRequiredDocument.vue';
import {FileEntry, IRequiredDocument, IRequiredDocumentCategory} from '../../models/file-entry';
import DossierRequiredDocumentCategory from '../../components/dossier/DossierRequiredDocumentCategory.vue';

@Component({
  components: {
    DossierRequiredDocumentCategory,
    FileTab,
    DossierPerson,
    DossierProperties,
    DossierRequiredDocument
  },
})
export default class DossierView extends Vue {

  public NO_CAT: IRequiredDocumentCategory = {
    category: {
      description: 'Bestanden die buiten een categorie vallen.',
      id: -1,
      name: 'Geen categorie',
      number: -1,
      sortindex: 100
    },
    documents: [],
  };
  public files: FileEntry[] = [];

  public tab: any = null;
  private loading: boolean = true;
  private filesLoading: boolean = true;
  private dossierId: number = 0;
  private description: string = '';
  private person1Id: number = 0;
  private person2Id: number = 0;
  private isClosed: boolean = false;
  private person1Name: string = 'Persoon 1';
  private person2Name: string = 'Persoon 2';
  private details: object = {};
  private p1d: object[] = [];
  private p2d: object[] = [];
  private dossierDetails: object[] = [];
  private requiredDocuments: IRequiredDocumentCategory[] = [];

  get getFiles(){
    return this.files;
  }
  created() {
	this.dossierId = parseInt(this.$route.params.id);
  }

  mounted() {
	this.fetchData();
  this.fetchFileData();

  }

  async reloadFiles(){
    await this.fetchFileData();
  }
  async fetchFileData() {
    this.filesLoading = true;
    const files = await getDossierFilesByDossier(this.dossierId);
    this.files = files.files;
    this.filesLoading=false;
  }

  async fetchData() {
	this.loading = true;
	const rsp = await getDossiersById(this.dossierId);
	this.description = rsp.dossier.description;
	this.isClosed = rsp.dossier.status == 1;
	this.person1Id = rsp.users[0].id;
	this.person1Name = rsp.users[0].name;
  if(rsp.users.length > 1) {
    this.person2Id = rsp.users[1].id;
    this.person2Name = rsp.users[1].name;
  }
	this.details = rsp.dossier;
	this.dossierDetails = rsp.details['dossier'];
	this.requiredDocuments = rsp.requredFiles;
	if (rsp.details.hasOwnProperty(this.person1Id)) {
	  this.p1d = rsp.details[this.person1Id];
	}
	if (rsp.details.hasOwnProperty(this.person2Id)) {
	  this.p2d = rsp.details[this.person2Id];
	}
	this.loading = false;
  }

  get dossierDescription(): string {
	return this.description;
  }

  get person1Fields() {
	if (this.person1Id <= 0) {
	  return [];
	}
	return this.p1d;
  }

  get person2Fields() {
	if (this.person2Id <= 0) {
	  return [];
	}
	return this.p2d;
  }

  get persoon1Naam() {
	return this.person1Name;
  }

  get persoon2Naam() {
	return this.person2Name;
  }
}
