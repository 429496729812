export function displayFieldValue(value: string, dataType: string) {
    switch (dataType) {
        case 'Bit':
            if (value === 'True') {
                return 'Ja';
            }
            return 'Nee';
        case 'Datetime':
            const dt = Date.parse(value);
            if (isNaN(dt)) {
                return value;
            }
            return new Date(dt).toDateString();
    }
    return value;
}
