
import {displayFieldValue} from '../../helpers/PerfectView';
import {Component, Prop, VModel, Watch} from 'vue-property-decorator';
import Vue from 'vue';
import {uploadDossierFile} from '../../services/app-service';
import {IRequiredDocument, IRequiredDocumentCategory} from '../../models/file-entry';
import DossierRequiredDocument from './DossierRequiredDocument.vue';

@Component({
  components: {
	DossierRequiredDocument
  },
  methods: {
	displayFieldValue
  }
})
export default class DossierRequiredDocumentCategory extends Vue {

  public emptyDocument: IRequiredDocument = {
	id: -1,
	name: 'Overig',
	isPresent: false
  };

  @Prop()
  public fileCategory!: IRequiredDocumentCategory;

  @Prop()
  public dossierId!: string;

  private clientcompletedFile: number = 0;

  bumpClientCompletedFiles() {
	this.clientcompletedFile++;
  }
  reloadFiles(){
    this.$emit('reloadFiles');
  }

  get totalFilesPresent() {
	let present = 0;
	this.fileCategory.documents.forEach(value => {
	  if (value.isPresent) {
		present++;
	  }
	});
	return present + this.clientcompletedFile;
  }

  get totalFilesRequired() {
	return this.fileCategory.documents.length;
  }

  get progressInPercent() {
	return Math.min(100, this.totalFilesPresent / this.totalFilesRequired * 100);
  }

  get getTitle() {
	if (this.fileCategory.category.id > 0) {
	  return `${this.fileCategory.category.number} - ${this.fileCategory.category.name}`;
	}
	return this.fileCategory.category.name;
  }

  get isValidCategory() {
	return this.fileCategory.category.id > 0;
  }

  get getIcon() {
	if (this.fileCategory.category.id > 0) {
	  return 'mdi-check';
	}
	return 'mdi-puzzle-outline';

  }
}
