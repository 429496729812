
import {Component, Vue} from 'vue-property-decorator';
import {authentication} from '@pazion/pazion-core/src/store';


@Component
export default class AppLogin extends Vue {
  private user: any = {email: '', password: ''};
  private loading: boolean = false;
  private autofilled: boolean = false;
  private message: string = '';

  created() {
    if (authentication.isUserLoggedIn) {
      this.$router.push('/');
    }
  }

  get canSubmit() {
    if (this.autofilled)
      return true;

    return this.user.email.length > 0 && this.user.password.length > 0;
  }

  mounted() {

    this.intervalDetectAutofill = setInterval(() => {

      if (document.querySelectorAll("input[type=\"password\"]:-webkit-autofill").length > 0) {

        this.$refs.username.$el.querySelector("label").classList.add("v-label--active");
        this.$refs.password.$el.querySelector("label").classList.add("v-label--active");

        this.autofilled = true;
        clearInterval(this.intervalDetectAutofill)

      }
    }, 100)

    // clean interval if needed after 3s
    setTimeout(() => {
      if (this.intervalDetectAutofill) {
        clearInterval(this.intervalDetectAutofill)
        this.intervalDetectAutofill = null
      }
    }, 3000)
  }

  handleLogin() {
    this.loading = true;
    this.message = '';
    if (!this.canSubmit) {
      this.loading = false;
      return;
    }
    authentication.login(this.user).then(
        (data) => {
          authentication.verifyUser();
          this.$router.push('/');
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 403) {
            this.message = 'Onjuiste inloggegevens, wachtwoord vergeten?';
          } else if (error.response.status == 400
              || error.response.status == 404) {
            this.message = 'Login server is niet bereikbaar';
          } else {
            this.message = 'Inloggen mislukt';
          }
        }
    );
  }
}
