
import {Component, Prop} from 'vue-property-decorator';
import PazToast from '@pazion/pazion-core/src/components/utils/PazToast.vue';
import {displayFieldValue} from '../../helpers/PerfectView';
import Vue from 'vue';
import {authentication} from '@pazion/pazion-core/src/store';
import {updateDossierProperty} from '../../services/app-service';

@Component({
  components: {
	PazToast
  },
  methods: {
	displayFieldValue
  }
})
export default class PersonProperty extends Vue {
  $refs!: {
	toast: PazToast
  };
  public saving: boolean = false;
  public propertyValue: string = '';
  @Prop()
  public canEdit!: boolean;
  @Prop()
  public field!: object;
  @Prop({default: ''})
  public dossierId!: string;

  mounted() {
	this.propertyValue = this.field.value;
  }

  async saveProperty(field: object, data: any) {
	this.saving = true;
	//console.log(field);
	const rsp = await updateDossierProperty(this.dossierId, field.name, data);
	this.$refs.toast.show({message: field.label + ' aangepast!'});
	this.saving = false;
  }
}
